// react
import { useState, useEffect } from "react";
// router
import { useNavigate } from "react-router";
// ant
import { Button, Form, Input, Image} from 'antd-mobile';

// actions
import * as Actions from '../Controllers/loginAction';

// source
import bg from '../Images/bg.png';

// css
import './staffs.css';

const Login = () => {

    // vars
    const navigate = useNavigate();

    // styles
    const main = {
        display: "flex",
        flexDirection: "column",
        width:"100%",
        justifyContent: "center",
        alignItems: "center",        
    };
    const form = {
        position:"fixed",
        width:"100%",
        marginTop: window.innerHeight / 2 ,
    };
    const styBg = {
        width:"100%",
        WebkitMask:"linear-gradient(black 90%, transparent 100%)"
    }

    // effect
    useEffect(()=>{
        
    },[])

    // states
    const [username, setUsername] = useState(0);
    const [password, setPassword] = useState(0);

    // funtions
    const onFinish = async () => {
        if(await Actions.loginAct(username, password)==1){navigate('/main');}
    };
    const handleUsername = (e) => { setUsername(e); };
    const handlePassword = (e) => { setPassword(e); };

    return (
        <>  
            <a href="#" onClick={(e)=>{navigate("../event")}} style={{fontSize:"12px",color:"#dddddd"}}>紧急工具</a>
            <div style={main}>
                {/* <Image src={bg} style={styBg} /> */}                
                <Form
                    layout='horizental'
                    mode='card'
                    style={form}                    
                    onFinish={onFinish}
                    footer={
                        <Button block type="submit" color="primary" size='large'>
                            登录
                        </Button>
                    }
                >
                    <Form.Item label='用户名' name='username' rules={[{ required: true, message: '用户名不能为空' }]}>
                        <Input onChange={handleUsername} value={username} placeholder='请输入用户名' clearable />
                    </Form.Item>
                    <Form.Item label='密码' name='password' rules={[{ required: true, message: '密码不能为空' }]}>
                        <Input onChange={handlePassword} value={password} placeholder='请输入密码' clearable type='password' />
                    </Form.Item>                    
                </Form>
            </div>
        </>
    )
}

export default Login;
