// react
import { useState, useEffect } from "react";
// router
import { useNavigate,Link } from "react-router-dom";
// ant
import { Image, Space, Button, Result,Picker,Input,Toast } from 'antd-mobile';
import { UserOutline, EnvironmentOutline, FlagOutline } from 'antd-mobile-icons'
// cookie
import cookie from 'react-cookies';


// actions
import * as Actions from '../Controllers/signAction';

// source
import bg from '../Images/bg.png';

// css
import './staffs.css';

/**
 * 
 * 名     ->    嘉宾资料
 * 活动   ->    所有活动   ->    嘉宾资料
 * 签到   ->    签到点     ->    嘉宾资料
 * 
 * @returns 
 */ 

const Sign = () => {

    // states
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState("");
    const [selected,setSelected] = useState("");
    const [hito_u,setHito_u] = useState("");
    const [group,setGroup] = useState(""); 
    const [list,setList] = useState([]);
    const [count,setCount] = useState(0);
    const [basicColumns,setBasicColumns] = useState([
        [            
            {
                label:"16_(全天)嘉宾现场报到",
                value:81
            },
            {
                label:"16_(下午)团长会议(会场扫码)",
                value:83
            },
            {
                label:"16_(晚上)酒店自助晚餐(所有人)",
                value:91
            },
            {
                label:"16_(晚上)欢迎晚宴(中国厅、上海厅会场扫,所有嘉宾)",
                value:85
            },
            {
                label:"17_恳亲大会扫码(星酒店及星湖大酒店)",
                value:86
            },
            {
                label:"17_恳亲大会扫码(温德姆酒店)",
                value:87
            },
            {
                label:"17_大会纪念标志揭幕仪式(指定人员)",
                value:90
            },
            {
                label:"17_(中午)酒店自助午餐(所有人)",
                value:92
            },
            {
                label:"17_经济研讨会(会场)",
                value:73
            },
            {
                label:"17_文化研讨会(会场)",
                value:74
            },
            {
                label:"17_(晚上)酒店自助晚餐(所有人)",
                value:93
            },
            {
                label:"17_(晚上)文艺汇演 进场",
                value:89
            },
            {
                label:"18_(早上)南雄珠玑巷寻根之旅（两日一夜）",
                value:68
            },
            {
                label:"18_(早上)西江流域广府文化体验之旅（两日一夜）",
                value:69
            },
        ]
    ]);

    // functions
    const handlePicker = (v) => {
        let bc = basicColumns[0];                                        
        for(let b in bc){
            if(bc[b]["value"]==v){                                                
                setValue(v);
                setSelected(bc[b]["label"]);
            }
        }                                        
    }
    const handleSearch = async (e) => {

        Toast.show({
            icon: 'loading',
            content: '请耐心等待结果返回',
        }); 

        if(value==""){
            alert("先选活动!");
            return;
        }
        let rs = await Actions.GetPointsData(value[0]);
        console.log(rs);
        if(rs==-4000){navigate("../main")};
        let ls = rs.ls;
        let ct  = rs.count;

        let data = [];
        if(group!=""){
            console.log(group);
            for(let l in ls){
                if(ls[l]["group_name"].indexOf(group)<0){
                    delete ls[l];
                }
            }
        }
        if(hito_u!=""){
            for(let l in ls){
                if(ls[l]["hito_u"].indexOf(hito_u)<0){
                    delete ls[l];
                }
            }
        }
        data = ls;

        setList(data);
        setCount(ct);
    }
    
    // styles
    const main = {     
        width:"100%",    
        height:window.innerHeight       
    };
    const content = {        
        position:"fixed",
        width:"100%",
        top:"1rem",         
    };    
    const styBg = {
        width:"100%",
        WebkitMask:"linear-gradient(black 90%, transparent 100%)"
    };
    const styNav = {
        marginTop:"10px",
        width:"100%",
        display:"flex",
        flexDirection:"row",
        alignItems:"flex-start",
        justifyContent:"flex-start",
        marginLeft:"10px"
    }; 
    const styNav2 = {
        marginLeft:"10px",
        marginTop:"10px",
        width:"100%",
    }; 
    const styMain = {
        margin:"10px",
        overflow: "scroll",
        height:window.innerHeight-300,
        color:"black"
    };

    // permission
    if ( cookie.load("username")=="admin" ) {
        return (
            <>
                <div>
                    <Result
                        status='warning'
                        title='403'
                        description={
                            <Space direction="vertical">
                                <div>未登录或账号没有操作权限，请重新登录</div>
                                <Link to="/login">重新登录</Link>
                            </Space>
                        }
                    />
                </div>
            </>
        )
    };

    // render
    return (
        <>
            <div style={main}>
                <div style={content}>
                    <div style={styNav}>                            
                        <Button
                            color="success"
                            size="small"
                            onClick={(e)=>{
                                navigate("../event");
                            }}
                        >
                            ←
                        </Button>                        
                    </div>
                    <div style={styNav2}>    
                        <Button
                            color="success"
                            onClick={() => {
                                setVisible(true)
                            }}
                            size="small"                            
                        >
                            选择活动
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b style={{color:"green"}}>{value}</b>
                        <Picker
                            columns={basicColumns}
                            visible={visible}
                            onClose={() => {
                                setVisible(false)
                            }}
                            value={value}
                            defaultValue={81}
                            onConfirm={handlePicker}                                    
                        />
                        <div style={{borderRadius:"4px",marginTop:"10px",width:"95%",color:"green"}}>
                            <span style={{fontSize:"15px"}}>选定活动：</span>                                    
                            <b style={{fontSize:"15px"}}>{selected}</b>
                        </div>                                                
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginTop:"10px"}}>
                        <Input
                            style={{backgroundColor:"white",color:"black",width:"88%",borderRadius:"4px",padding:"1%"}}
                            placeholder='按嘉宾姓名' 
                            value={hito_u} 
                            onChange={(e)=>{setHito_u(e)}}
                        />
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginTop:"10px"}}>
                        <Input
                            style={{backgroundColor:"white",color:"black",width:"88%",borderRadius:"4px",padding:"1%"}}
                            placeholder='按社团' 
                            value={group} 
                            onChange={(e)=>{setGroup(e)}}
                        />
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginTop:"10px"}}>                            
                        <Button
                            style={{width:"90%",}}
                            color="success"
                            onClick={handleSearch}
                            size="small"                            
                        >
                            搜索
                        </Button>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginTop:"10px"}}>                            
                        未签到总数:{count}
                    </div>
                    <div style={styMain}>
                        
                        <table className="list">
                            {(list).map((item,index)=>{
                                return(
                                    <tr>
                                        <td>
                                            {item.hito_u}
                                        </td>
                                        <td>
                                            {item.group_name}
                                        </td>
                                        <td>
                                            {item.staff_name}
                                        </td>
                                        <td>
                                            <a href={"tel: "+item.staff_tel}>{item.staff_tel}</a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sign;
