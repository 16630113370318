//核心
import { React , forwardRef } from 'react';
// router
import { Link } from "react-router-dom";
// ant
import { Space, Result } from 'antd-mobile';

const Page403 = forwardRef((props,ref) => {

    const styErrDiv = {
        padding:"0.5rem",
        width:"auto",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center"
    }
    const styErrResult = {
        width:"100%",
        borderRadius:"12px"
    }

    return (
        <>
            <div style={styErrDiv}>
                <Result
                    style={styErrResult}
                    status='warning'
                    title='403'
                    description={
                        <Space direction="vertical">
                            <div>未登录或账号没有操作权限，请重新登录</div>
                            <Link to="/login">重新登录</Link>
                        </Space>
                    }
                />
            </div>
        </>
    )

});

export default Page403;