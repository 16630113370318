// ant
import { Toast } from 'antd-mobile';
// cookies
import cookie from 'react-cookies';

// service
import{ 
    get_ls_by_pointid,     
} from '../Services/eventService';

/**
 * 获取签到数据
 * @param {*} params 
 * @returns 
 */
function GetPointsData(pointid){
    let rs = get_ls_by_pointid({
        "event_id": 15,
        "pointid": pointid,
        "signed": 0,
    }).then((response)=>{
        let res = response.data.res;
        if(res==-4000){
            return res;
        }
        let data = response.data.data;
        return data;
    });

    return rs;
}

export { 
    GetPointsData, 
}