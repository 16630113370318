// react
import { useState, useEffect } from "react";
// router
import { useNavigate,Link } from "react-router-dom";
// ant
import { Image, Space, Button, Result, } from 'antd-mobile';
import { UserOutline, EnvironmentOutline, FlagOutline } from 'antd-mobile-icons'
// cookie
import cookie from 'react-cookies';


// actions
import * as Actions from '../Controllers/eventAction';

// source
import bg from '../Images/bg.png';

// css
import './staffs.css';

/**
 * 
 * 名     ->    嘉宾资料
 * 活动   ->    所有活动   ->    嘉宾资料
 * 签到   ->    签到点     ->    嘉宾资料
 * 
 * @returns 
 */ 

const Event = () => {

    // states
    const navigate = useNavigate();
    
    // styles
    const main = {     
        width:"100%",    
        height:window.innerHeight       
    };
    const content = {        
        position:"fixed",
        width:"100%",
        top:"1rem",         
    };    
    const styBg = {
        width:"100%",
        WebkitMask:"linear-gradient(black 90%, transparent 100%)"
    };
    const styNav = {
        width:"100%",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-around"
    }; 
    const styMain = {
        margin:"10px",
        overflow: "scroll",
        height:window.innerHeight-100,
        color:"black"
    };

    // permission
    if ( cookie.load("username")=="admin" ) {
        return (
            <>
                <div>
                    <Result
                        status='warning'
                        title='403'
                        description={
                            <Space direction="vertical">
                                <div>未登录或账号没有操作权限，请重新登录</div>
                                <Link to="/login">重新登录</Link>
                            </Space>
                        }
                    />
                </div>
            </>
        )
    };

    // render
    return (
        <>
            <div style={main}>
                <div style={content}>
                    <div style={styNav}>                            
                        <Button
                            color="success"
                            size="small"
                            onClick={(e)=>{
                                navigate("../login");
                            }}
                        >
                            ←
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            onClick={(e)=>{
                                navigate("../sign");
                            }}
                        >
                            签到统计
                        </Button>
                    </div>
                    <div style={styMain}>
                        <table className="list">
                            <tr><td>sgh1</td><td>董瑞敏</td></tr>
                            <tr><td>sgh2</td><td>陈彦宏</td></tr>
                            <tr><td>sgh3</td><td>杨健强</td></tr>
                            <tr><td>sgh4</td><td>周暑迪</td></tr>
                            <tr><td>sgh5</td><td>曾琼远</td></tr>
                            <tr><td>sgh6</td><td> 李楷</td></tr>
                            <tr><td>sgh7</td><td>黄翠莲</td></tr>
                            <tr><td>sgh8</td><td>李钧权</td></tr>
                            <tr><td>sgh9</td><td>黄斯彬</td></tr>
                            <tr><td>sgh10</td><td>黄玉柱</td></tr>
                            <tr><td>sgh11</td><td>刘丽明</td></tr>
                            <tr><td>sgh12</td><td>鲍哲宇</td></tr>
                            <tr><td>sgh13</td><td>白宜嘉</td></tr>
                            <tr><td>sgh14</td><td>李国钊</td></tr>
                            <tr><td>sgh15</td><td>祝家明</td></tr>
                            <tr><td>sgh16</td><td>梁志坚</td></tr>
                            <tr><td>sgh17</td><td>邱小丽</td></tr>
                            <tr><td>sgh18</td><td>颜龙梅</td></tr>
                            <tr><td>sgh19</td><td>黄燕华</td></tr>
                            <tr><td>sgh20</td><td>曾海富</td></tr>
                            <tr><td>sgh21</td><td>祝家明</td></tr>
                            <tr><td>sgh22</td><td>杨丰恺</td></tr>
                            <tr><td>sgh23</td><td>程正勇</td></tr>
                            <tr><td>sgh24</td><td>夏丹</td></tr>
                            <tr><td>sgh25</td><td>曾通辉</td></tr>
                            <tr><td>sgh26</td><td>陈幼虹</td></tr>
                            <tr><td>sgh27</td><td>黄紫欣</td></tr>
                            <tr><td>sgh28</td><td>蔡炜诗</td></tr>
                            <tr><td>sgh29</td><td>许鸿莉</td></tr>
                            <tr><td>sgh30</td><td>何家敏</td></tr>
                            <tr><td>sgh31</td><td>卓伟男</td></tr>
                            <tr><td>sgh32</td><td>郑培嘉</td></tr>
                            <tr><td>sgh33</td><td>张嘉显</td></tr>
                            <tr><td>sgh34</td><td>林楚卿</td></tr>
                            <tr><td>sgh35</td><td>廉艳红</td></tr>
                            <tr><td>sgh36</td><td>刘一儿</td></tr>
                            <tr><td>sgh37</td><td>温博雯</td></tr>
                            <tr><td>sgh38</td><td>罗立玲</td></tr>
                            <tr><td>sgh39</td><td>黄银龙</td></tr>
                            <tr><td>sgh40</td><td>陈学琛</td></tr>
                            <tr><td>sgh41</td><td>卢如萍</td></tr>
                            <tr><td>sgh42</td><td>贾晶</td></tr>
                            <tr><td>sgh43</td><td>黄晓佳</td></tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Event;
