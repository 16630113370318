// 核心
import { React } from 'react';
import ReactDOM from 'react-dom/client';

// 路由
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Login from './Pages/login.js';
import Main from './Pages/main.js';
import Event from './Pages/event.js';
import Sign from './Pages/sign.js';

//cookie
import cookie from "react-cookies";
// ahooks
import {useTitle} from 'ahooks';

//根组件
const App = () => {
  
  useTitle("管家信息工具");
  //获取Link组件路由钩子传送的参数
  const Location = useLocation();
  const link_pathname = Location.state;

  //获取当前路径
  let pathname = window.location.pathname;
  //如果路径为根，菜单点亮key为/login
  if (pathname === "/") {
      pathname = "/login";
  }
  if (link_pathname != null) {
      pathname = link_pathname;
  }

  return (
    <>
      {/* 路由渲染位置 */}
      <Routes>
          {/* 跟目录默认路由 */}
          <Route path="/" element={<Login />} exact />
          
          {/* staffs路由 */}
          <Route path="/login" element={<Login />} />
          <Route path="/main" element={<Main />} />
          <Route path="/event" element={<Event />} />
          <Route path="/sign" element={<Sign />} />
      </Routes>
    </>
  )
};

//创建根节点
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //路由配置
    <Router>        
        {/* 应用根组件 */}
        <App />
    </Router>
);