// axios
import Axios from "axios";
// Cookies
import cookie from 'react-cookies';

const url = "https://interface.cctrip.cc";
//const url = "http://192.168.120.2:8880";

//
const search_group_ls = async (event_id,group_id) => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        url+'/api/event/search_group_ls',
        {
            "event_id": event_id,
            "id": group_id,
            "page": 0,
            "pagesize": 1,
            "keys":[
                "contact_n",
                "contact_tel",
                "contact_tel2"
            ]
        }
    );
};

// 
const get_staff_list = async (args) => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        url+'/api/event/get_staff_list',
        {
            "type": args.type
        }
    );
}

/**
 *  staff_id2 管家id
    staff_tel 管家电话
    staff_name 管家姓名
    logtime 是否到达   null 为未到, 否则是到达时间
 * @param {*} event_id 
 * @param {*} group_id 
 * @returns 
 */
const search_hito = (event_id,staff_id2) => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        url+'/api/event/search_hito', 
        {
            "event_id": event_id,
            "page": 0,
            "staff_id2": staff_id2,
            "pagesize": 10000,
            "keys": [
                "memo",
                "onoff",
                "id",
                "tel_1",
                "code",
                "group_id",
                "group_name",
                "hito_u",
                "repast_1",
                "repast_2",
                "staff_id2",
                "staff_tel",
                "staff_name",
                "qrcode",
                "arrived",
                "logtime",
                "staff_tel",
                "staff_name",
                "staff_id2",
                "hotel_name",                   
                
                "sub_meeting4",
                "sub_meeting6",
                "sub_meeting14",
                "sub_meeting8",
                "sub_meeting9",
                "sub_meeting10",
                "sub_meeting13",
                "sub_meeting15",
                "sub_meeting3",
                "sub_meeting16",
                "sub_meeting12",
                "sub_meeting",

                "logtime_3_jj",             // 经济论坛(会场扫码)
                "logtime_3_wh",             // 文化论坛(会场扫码)
                "logtime_1_xj",             // 西江流域广府文化体验之旅（管家分车扫码）
                "logtime_1_nx",             // 南雄珠玑巷“寻根之旅”活动（管家分车扫码）
                "logtime_4",
                "logtime_6",
                "logtime_14",
                "logtime_8",
                "logtime_9",
                "logtime_10",
                "logtime_13",
                "logtime_15",
                "logtime_3",
                "logtime_16",
                "logtime_12",
                
                "pw",
                "preset_point_id",
            ]
        }
        
    )
};


// 获取活动签到点
const get_sign_point = () => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        url+'/api/event/get_sign_point', 
        {
            "event_id": 15,
            "keys":[
                "id",
                "memo",
                "isopen",
                "group"
            ],
            "page":0,
            "pagesize":1000,
            "order":"id desc"
        }
    ); 
};

// 获取活动签到点的签到人数等数据
const get_ls_by_pointid = (args) => {
    const ajax = Axios.create({
        headers: { 'Authorization': 'Bearer '+cookie.load("token") }
    });
    return ajax.post(
        'https://interface.cctrip.cc/api/event/get_ls_by_pointid', 
        {
            "event_id": args.event_id,    //活动id
            "pointid": args.pointid,      //签到点id
            "signed": args.signed,        //1只筛选已签到 0未签到的名单列表
            "keys": [],
            "page":0,
            "pagesize":10000
        }
    ); 
};



export {     
    get_staff_list,
    search_hito,
    get_sign_point,
    search_group_ls,
    get_ls_by_pointid
};