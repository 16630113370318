// react
import { useState, useEffect } from "react";
// router
import { useNavigate,Link } from "react-router-dom";
// ant
import { Image, Button,Input, Form, Picker,Card,List,Space,Tag,Toast,Dialog,Collapse } from 'antd-mobile';
import { SearchOutline , UpCircleOutline , DownCircleOutline} from 'antd-mobile-icons'
// cookie
import cookie from 'react-cookies';
// ahooks
import {useSafeState, useTitle} from 'ahooks';

// css
import './staffs.css';


// actions
import * as Actions from '../Controllers/mainAction';
// Components
import Page403 from '../Components/page403';

// source
import bg from '../Images/bg.png';

/**
 * 
 * 名     ->    嘉宾资料
 * 活动   ->    所有活动   ->    嘉宾资料
 * 签到   ->    签到点     ->    嘉宾资料
 * 
 * @returns 
 */ 

const Main = () => {

    // states
    const [dispPlace,setDispPlace] = useState("block");
    const [dispName,setDispName] = useState("block");
    const [dispSigned,setDispSigned] = useState("block");
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState("");
    const [selected,setSelected] = useState("");
    const [visible2, setVisible2] = useState(false);
    const [value2, setValue2] = useState("");
    const [selected2,setSelected2] = useState("");
    const [signPoints,setSignPoints] = useState({});
    const [hitos,setHitos] = useState([]);
    const [hito_u,setHito_u] = useState("");
    const [counts,setCounts] = useState(0);
    const [basicColumns2,setBasicColumns2] = useState([
        [
            {
                label:"不限",
                value:2
            },
            {
                label:"未签到",
                value:0
            },
            {
                label:"已签到",
                value:1
            },
        ]
    ]);
    const [basicColumns,setBasicColumns] = useState([
        [
            {
                label:"不限",
                value:-1
            },
            {
                label:"16_(全天)嘉宾现场报到",
                value:81
            },
            {
                label:"16_(下午)团长会议(会场扫码)",
                value:83
            },
            {
                label:"16_(晚上)酒店自助晚餐(所有人)",
                value:91
            },
            {
                label:"16_(晚上)欢迎晚宴(中国厅、上海厅会场扫,所有嘉宾)",
                value:85
            },
            {
                label:"17_恳亲大会扫码(星酒店及星湖大酒店)",
                value:86
            },
            {
                label:"17_恳亲大会扫码(温德姆酒店)",
                value:87
            },
            {
                label:"17_大会纪念标志揭幕仪式(指定人员)",
                value:90
            },
            {
                label:"17_(中午)酒店自助午餐(所有人)",
                value:92
            },
            {
                label:"17_经济研讨会(会场)",
                value:73
            },
            {
                label:"17_文化研讨会(会场)",
                value:74
            },
            {
                label:"17_(晚上)酒店自助晚餐(所有人)",
                value:93
            },
            {
                label:"17_(晚上)文艺汇演 进场",
                value:89
            },
            {
                label:"18_(早上)南雄珠玑巷寻根之旅（两日一夜）",
                value:68
            },
            {
                label:"18_(早上)西江流域广府文化体验之旅（两日一夜）",
                value:69
            },
        ]
    ]);
    
    // functions
    const SearchHito = async ()=>{        
        let sp = await Actions.GetSignPoint();
        let obj = {};
        for(let s in sp){
            obj[sp[s]["id"]] = sp[s];
        }
        setSignPoints(obj);        
        let sh = await Actions.SearchHito(cookie.load("staff_id"));       
        setHitos(sh);        
        Toast.show({
            icon: 'loading',
            content: '搜索',
        });  
        return sh;
    };
    const handleLogin = async (e)=>{        
        setHito_u("");
        setValue("");
        setSelected("");
        setValue2("");
        setSelected2("");
        SearchHito();
        setDispName("block");
        setDispPlace("block");
        setDispSigned("block");
    };
    const handleSignPoints = async (e) => {
        let sp = await Actions.GetSignPoint();
        let obj = {};
        for(let s in sp){
            obj[sp[s]["id"]] = sp[s];
        }
        setSignPoints(obj); 
    };
    const handleSearch = async (e) => {   
        let sh = await Actions.SearchHito(cookie.load("staff_id"));
        let sh_filter = sh;
        for(let s in sh_filter){
            
            let exist = 0;        
            if(sh_filter[s]["hito_u"].indexOf(hito_u)<0 && hito_u!=""){
                delete sh_filter[s];
            }
            
            if(selected!="" && value[0]!=-1 && sh_filter[s]){
                    
                if(sh_filter[s]["sub_meeting4"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting6"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting14"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting8"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting9"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting10"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting13"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting15"]==value[0]){exist=1;}
                if(value[0]==73 && sh_filter[s]["memo"]=="均参加"){exist=1;}
                if(value[0]==73 && sh_filter[s]["memo"]=="经济研讨会"){exist=1;}
                if(value[0]==74 && sh_filter[s]["memo"]=="均参加"){exist=1;}
                if(value[0]==74 && sh_filter[s]["memo"]=="文化研讨会"){exist=1;}
                if(sh_filter[s]["sub_meeting3"]==value[0]){exist=1;} 
                if(sh_filter[s]["sub_meeting16"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting12"]==value[0]){exist=1;}
                if(sh_filter[s]["sub_meeting"]==68 && value[0]==68){
                    exist=1;
                }
                if(sh_filter[s]["sub_meeting"]==69 && value[0]==69){
                    exist=1;
                }

                if(exist!=1){                    
                    delete sh_filter[s];
                }
            }
            
            let logtimeObj = {
                81:"logtime_4",
                83:"logtime_6",
                91:"logtime_14",
                85:"logtime_8",
                86:"logtime_9",
                87:"logtime_10",
                90:"logtime_13",
                92:"logtime_15",
                73:"logtime_3_jj",
                74:"logtime_3_wh",
                93:"logtime_16",
                89:"logtime_12",
                68:"logtime_1_nx",
                69:"logtime_1_xj",
            };

            if(selected2!="" && value2[0]!=2 && sh_filter[s]){
                if(value2[0]==1){ 
                    if(sh_filter[s][logtimeObj[value[0]]]==null){
                        delete sh_filter[s];
                    }
                }
                if(value2[0]==0){ 
                    if(sh_filter[s][logtimeObj[value[0]]]!=null){
                        delete sh_filter[s];
                    }
                }
            }
        }
        let length = 0;        
        for(let ss in sh_filter){
            length++;                
        }
        setCounts(length);
        setHitos(sh_filter);
        setDispName("none");
        setDispPlace("none");
        setDispSigned("none");
        Toast.show({
            icon: 'loading',
            content: '筛选查询!',
        });
    };
    const handleContacts = async (e) => {
        let group_id = e.currentTarget.dataset["group_id"];
        let group_name = e.currentTarget.dataset["group_name"];
        let rs = await Actions.SearchGroupLs(group_id);
        let contacts = rs.data.data[0];
        Dialog.alert({
            title: '联系方式',
            content: <>
                <List mode='card'>
                    <List.Item extra={group_name}>社团</List.Item>
                    <List.Item extra={contacts.contact_n}>联系人</List.Item>
                    <List.Item extra={contacts.contact_tel}>联系电话1</List.Item>
                    <List.Item extra={contacts.contact_tel2}>联系电话2</List.Item>
                </List>
            </>
        });
    }
    const handleHavelogs = async (e) => {
        setDispName("none");
        setDispPlace("none");
        setDispSigned("none");
        let ht = await SearchHito();
        let sh = [];
        for(let h in ht){
            if(ht[h]["logcount"]>0){
                sh.push(ht[h]);
            }
        }
        setHitos(sh);
    }
    const hadnleNologs = async (e) => {
        setDispName("none");
        setDispPlace("none");
        setDispSigned("none");
        let ht = await SearchHito();
        let sh = [];
        for(let h in ht){
            if(ht[h]["logcount"]==0){
                sh.push(ht[h]);
            }
        }
        setHitos(sh);
    }

    // hooks
    const navigate = useNavigate();
    useTitle("管家信息工具");
    useEffect(()=>{
        handleSignPoints();
        SearchHito().then((res)=>{
            setCounts(res.length);
        });
    },[]);
    
    // styles
    const main = {     
        width:"100%",    
        height:window.innerHeight       
    };
    const content = {        
        position:"fixed",
        width:"100%",
        top:"0rem",         
    };    
    const styBg = {
        width:"100%",        
    };
    const styNav = {
        width:"100%",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-around"
    }; 
    const styMain = {        
        margin:"12px",
        overflow: "scroll",
        height:window.innerHeight - 160,
    };    
    const styItemAct = {
        color:"#888888",
        fontSize:"15px",
        marginBottom:"10px",
        borderBottom:"dashed 1px #cccccc"
    };
    const styItemName = {
        backgroundColor:"green",
        color:"white",
        fontSize:"20px"
    };
    const styItemTitle = {
        color:"#666666",
        fontWeight:"bold",
        fontSize:"16px"
    };

    // permission
    if ( !cookie.load("username") ) {
        return (
            <>
                <Page403 />
            </>
        )
    };

    // render
    return (
        <>
            <div style={main}>
                <div style={content}>
                    <div>
                    <Form layout='vertical' mode='card'>
                            <Form.Item label="嘉宾姓名"  style={{display:dispName}}> 
                                <Input placeholder='请输入' value={hito_u} onChange={(e)=>{setHito_u(e)}}/>
                            </Form.Item>
                            <Form.Item style={{display:dispSigned}}>
                                <Button
                                    onClick={() => {
                                        setVisible2(true)
                                    }}
                                    block
                                    size="small"                            
                                >
                                    选择签到状态
                                </Button>
                                <Picker
                                    columns={basicColumns2}
                                    visible={visible2}
                                    onClose={() => {
                                        setVisible2(false)
                                    }}
                                    value={value2}
                                    defaultValue={0}
                                    onConfirm={(v) => {
                                        let bc = basicColumns2[0];                                        
                                        for(let b in bc){
                                            if(bc[b]["value"]==v){                                                
                                                setValue2(v);
                                                setSelected2(bc[b]["label"]);
                                            }
                                        }                                        
                                    }}                                    
                                />
                                <div style={{borderRadius:"4px",marginTop:"10px"}}>
                                    <span style={{fontSize:"15px"}}>选定状态：</span>                                    
                                    <b>{selected2}</b>
                                </div>
                            </Form.Item>
                            <Form.Item style={{display:dispPlace}}>
                                <Button
                                    onClick={() => {
                                        setVisible(true)
                                    }}
                                    block
                                    size="small"                            
                                >
                                    选择活动
                                </Button>
                                <Picker
                                    columns={basicColumns}
                                    visible={visible}
                                    onClose={() => {
                                        setVisible(false)
                                    }}
                                    value={value}
                                    defaultValue={0}
                                    onConfirm={(v) => {
                                        let bc = basicColumns[0];                                        
                                        for(let b in bc){
                                            if(bc[b]["value"]==v){                                                
                                                setValue(v);
                                                setSelected(bc[b]["label"]);
                                            }
                                        }                                        
                                    }}                                    
                                />
                                <div style={{borderRadius:"4px",marginTop:"10px"}}>
                                    <span style={{fontSize:"15px"}}>选定活动：</span>                                    
                                    <b style={{fontSize:"15px"}}>{selected}</b>
                                </div>
                            </Form.Item>  
                            <Form.Item label={<span style={{fontSize:"15px"}}>
                                                统计信息
                                                <span style={{float:"right"}}>
                                                    <a href="#" style={{fontSize:"15px",color:"#cccccc"}} onClick={handleHavelogs}>曾签</a>
                                                    &nbsp;&nbsp;                                    
                                                    <a href="#" style={{fontSize:"15px",color:"#cccccc"}} onClick={hadnleNologs}>不曾签</a>
                                                </span>
                                              </span>} >
                                    <span style={{fontSize:"15px"}}><b>{counts}</b>&nbsp;&nbsp;位嘉宾</span>
                                    <span style={{fontSize:"15px"}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span style={{float:"right"}}>                                    
                                    <span style={{fontSize:"15px",color:"#cccccc"}}>{cookie.load("username")}</span>
                                    <span style={{fontSize:"15px"}}>&nbsp;&nbsp;</span>
                                    <span style={{fontSize:"15px",color:"#cccccc"}}>{cookie.load("staff_id")}</span>
                                    <span style={{fontSize:"15px"}}>&nbsp;&nbsp;</span>
                                    <span style={{fontSize:"15px",color:"#cccccc"}}>{cookie.load("tn")}</span>
                                    </span>
                                    
                            </Form.Item> 
                            <Form.Item>
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"center"}}>   
                                    <Button
                                        color="default"
                                        onClick={()=>{
                                            navigate('../login');
                                        }}
                                        size="small" 
                                    >
                                        注销
                                    </Button>                                 
                                    <Button
                                        color="default"
                                        onClick={handleLogin}
                                        size="small" 
                                    >
                                        重置
                                    </Button>
                                    <Button    
                                        color="success"
                                        size="small" 
                                        onClick={handleSearch}
                                    >
                                        <SearchOutline />
                                        <span>&nbsp;</span>
                                        <span>搜索</span>
                                    </Button>
                                    <Button
                                        color="default"
                                        onClick={(e)=>{
                                            if(dispName=="none"){setDispName("block");}else{setDispName("none");}
                                            if(dispPlace=="none"){setDispPlace("block");}else{setDispPlace("none");}
                                            if(dispSigned=="none"){setDispSigned("block");}else{setDispSigned("none");}
                                        }}
                                        size="small" 
                                    >
                                        {(dispName=="block")?
                                            <UpCircleOutline />:
                                            <DownCircleOutline />
                                        }
                                        &nbsp;折叠
                                    </Button>
                                </div>
                            </Form.Item>                 
                    </Form>
                    </div>
                    <div style={styMain}>
                    {hitos.map((item,index)=>{
                    return(
                        <div>
                            <List mode='card'>
                                <List.Item extra={<Button
                                        color="success"
                                        size="small"
                                        data-group_id={item.group_id}
                                        data-group_name={item.group_name}
                                        onClick={handleContacts}
                                    >
                                        联系方式
                                    </Button>} style={styItemName}>
                                    {item.no} - {item.hito_u}                                                                        
                                </List.Item>
                                <Collapse accordion defaultActiveKey="0">
                                <Collapse.Panel key='1' title={
                                    <span style={{fontSize:"12px"}}>
                                        详细&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{color:"#cccccc"}}>已签到<b>{item.logcount}</b>个点</span>
                                    </span>
                                }>
                                <List.Item extra={item.code} style={styItemTitle}>邀请码</List.Item>
                                <List.Item extra={item.hito_u} style={styItemTitle}>嘉宾姓名</List.Item>
                                <List.Item extra={item.tel_1} style={styItemTitle}>嘉宾电话</List.Item>
                                <List.Item extra={item.group_name} style={styItemTitle}>社团名称</List.Item>
                                <List.Item extra={item.hotel_name} style={styItemTitle}>入住酒店</List.Item>
                                <List.Item extra={item.pw} style={styItemTitle}>开幕式分区</List.Item>
                                <List.Item extra={item.preset_point_id} style={styItemTitle}>团长会议座号</List.Item>
                                <List.Item extra={item.repast_1} style={styItemTitle}>16日晚宴厅</List.Item>
                                <List.Item extra={item.repast_2} style={styItemTitle}>16日晚宴桌号</List.Item>
                                <List.Item style={styItemTitle}>活动明细</List.Item>
                                <List.Item style={{styItemAct}}>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting4>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        16_(全天)嘉宾现场报到
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(81m4)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_4!=null)?
                                            <Tag color="primary">{item.logtime_4}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting6>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        16_(下午)团长会议(会场扫码)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(83m6)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_6!=null)?
                                            <Tag color="primary">{item.logtime_6}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting14>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        16_(晚上)酒店自助晚餐(所有人)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(91m14)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_14!=null)?
                                            <Tag color="primary">{item.logtime_14}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting8!=null)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        16_(晚上)欢迎晚宴(中国厅、上海厅会场扫,所有嘉宾)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(85m8)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_8!=null)?
                                            <Tag color="primary">{item.logtime_8}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting9>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_恳亲大会扫码(星酒店及星湖大酒店)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(86m9)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_9!=null)?
                                            <Tag color="primary">{item.logtime_9}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting10>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_恳亲大会扫码(温德姆酒店)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(87m10)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_10!=null)?
                                            <Tag color="primary">{item.logtime_10}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting13>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_大会纪念标志揭幕仪式(指定人员)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(90m13)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_13!=null)?
                                            <Tag color="primary">{item.logtime_13}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting15>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_(中午)酒店自助午餐(所有人)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(92m15)</span>
                                        &nbsp;&nbsp;
                                        {(item.logtime_15!=null)?
                                            <Tag color="primary">{item.logtime_15}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.memo=="均参加" || item.memo=="经济研讨会")?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17 _经济研讨会 (所有人均可)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(73m3)</span>
                                        {(item.logtime_3_jj!=null)?
                                            <Tag color="primary">{item.logtime_3_jj}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.memo=="均参加" || item.memo=="文化研讨会")?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_文化研讨会(所有人均可)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(74m3)</span>
                                        {(item.logtime_3_wh!=null)?
                                            <Tag color="primary">{item.logtime_3_wh}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting16>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_(晚上)酒店自助晚餐(所有人)
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(93m16)</span>
                                        {(item.logtime_16!=null)?
                                            <Tag color="primary">{item.logtime16}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting12>0)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        17_(晚上)文艺汇演 进场
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(89m12)</span>
                                        {(item.logtime_12!=null)?
                                            <Tag color="primary">{item.logtime_12}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting==68)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }   
                                        &nbsp;&nbsp;
                                        18_(早上)南雄珠玑巷寻根之旅（两日一夜）
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(68m1)</span>
                                        {(item.logtime_1_nx!=null)?
                                            <Tag color="primary">{item.logtime_1_nx}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                    <div style={styItemAct}>
                                        {(item.sub_meeting==69)?
                                            <Tag color="success">参&nbsp;&nbsp;&nbsp;&nbsp;加</Tag>:
                                            <Tag color="default">不参加</Tag>
                                        }
                                        &nbsp;&nbsp;
                                        18_(早上)西江流域广府文化体验之旅（两日一夜）
                                        <div style={{textAlign:"right"}}>
                                        <span style={{color:"#cccccc"}}>(69m1)</span>
                                        {(item.logtime_1_xj!=null)?
                                            <Tag color="primary">{item.logtime_1_xj}</Tag>:
                                            <Tag color="default">未签到</Tag>
                                        }
                                        </div>
                                    </div>
                                </List.Item>   
                                </Collapse.Panel>
                                </Collapse>                                                    
                            </List>   
                        </div>
                        )
                    })}                          
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;
