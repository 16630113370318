// ant
import { Toast,Dialog } from 'antd-mobile';
// icon
import { ExclamationCircleFill } from 'antd-mobile-icons'
// cookies
import cookie from 'react-cookies';

// Services
import{ 
    login,
    decode_token,
} from '../Services/loginService';
import{ 
    get_staff_list
} from '../Services/eventService';


/*
    管家
    sgh1
    非管家
    sgh100
    密码均是2023
*/

/**
 * 登录
 * @param {*} u 
 * @param {*} p 
 * @returns 
 */
const loginAct = async (u, p) => {

    let expin = "";
    let exp = "";

    // 管家id范围
    const res = await get_staff_list({
        type:1
    });
    let staffs = res.data.data;

    // 普通登录
    const response = await login(u,p);
    let data = response.data;
    if(data==""){
        //消息框，提示错误
        Dialog.alert({
            header: (
                <ExclamationCircleFill
                  style={{
                    fontSize: 64,
                    color: 'var(--adm-color-danger)',
                  }}
                />
            ),
            title:'',
            content: '登录失败,请检查用户名或密码!',
        });
        //登录不正确，清空原有登录信息
        cookie.remove("username");
        cookie.remove("token");
        cookie.remove("staff_id");
        cookie.remove("tn");
        return 0;
    }else{
        //获取过期时间
        expin = data.expin;
        exp = new Date(expin * 1000);
        //获取token
        let token = data.data.token;
        //保存cookie
        cookie.save("username", u, { expires: exp });
        cookie.save("token", token, { expires: exp });          
    }

    // 管家判断
    let token = response.data.data.token;
    const response2 = await decode_token(u,p,token);
    let perm_arr = [];
    for(let s in staffs){
        perm_arr.push(staffs[s]["id"]);
    }
    if(response2.data.data==""){
        Dialog.alert({
            header: (
                <ExclamationCircleFill
                  style={{
                    fontSize: 64,
                    color: 'var(--adm-color-danger)',
                  }}
                />
            ),
            title:'',
            content: '登录失败,该账号没有使用系统权限，请用有权限账号重新登录!',
        });
        //登录不正确，清空原有登录信息
        cookie.remove("username");
        cookie.remove("token");
        cookie.remove("staff_id");
        cookie.remove("tn");
        return 0;
    }
    if(!perm_arr.includes(response2.data.data.data.id)){
        Dialog.alert({
            header: (
                <ExclamationCircleFill
                  style={{
                    fontSize: 64,
                    color: 'var(--adm-color-danger)',
                  }}
                />
            ),
            title:'',
            content: '登录失败,该账号没有使用系统权限，请用有权限账号重新登录!',
        });
        //登录不正确，清空原有登录信息        
        cookie.remove("username");
        cookie.remove("token");
        cookie.remove("staff_id");
        cookie.remove("tn");
        return 0;
    }else{
        //登录成功默认跳转页    
        let staff_id = response2.data.data.data.id;
        let tn = response2.data.data.data.tn;
        cookie.save("staff_id", staff_id, { expires: exp });
        cookie.save("tn", tn, { expires: exp });
        Toast.show({
            icon: 'success',
            content: '成功登录!',
        });   
        return 1;
    }
}

export { 
    loginAct, //登录 -- (因为重名加Act加以区分)
};