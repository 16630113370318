// ant
import { Toast } from 'antd-mobile';
// cookies
import cookie from 'react-cookies';

// Services
import{ 
    get_staff_list,
    search_hito,
    get_sign_point,
    search_group_ls,
} from '../Services/eventService';


/**
 * 
 * @param {*} group_id 
 * @returns 
 */
const SearchGroupLs = (event_id,group_id) => {
    return search_group_ls(15,group_id);
}

/**
 * 
 * @returns 
 */
const GetStaffList = () => {
    return get_staff_list({
        type:1
    })
};

/**
 * 
 * @returns 
 */
const GetSignPoint = async () => {
    const response = await get_sign_point();
    return response.data.data;
}

/**
 * 
 * @param {*} staff_id2 
 * @returns 
 */
const SearchHito = async (staff_id2) => {
    const response = await search_hito(15,staff_id2);
    let ds = [];
    let dn = [];
    let data = response.data.data;
    let i=1;
    for(let d in data){
        data[d]["no"] = i;
        dn.push(data[d]);
        i++;
    }
    for(let d in dn){
        if(dn[d]["onoff"]==1){
            
            let logcount = 0;
            if(dn[d]["logtime_4"]!=null){logcount++;}
            if(dn[d]["logtime_6"]!=null){logcount++;}
            if(dn[d]["logtime_14"]!=null){logcount++;}
            if(dn[d]["logtime_8"]!=null){logcount++;}
            if(dn[d]["logtime_9"]!=null){logcount++;}
            if(dn[d]["logtime_10"]!=null){logcount++;}
            if(dn[d]["logtime_13"]!=null){logcount++;}
            if(dn[d]["logtime_15"]!=null){logcount++;}
            if(dn[d]["logtime_3_jj"]!=null){logcount++;}
            if(dn[d]["logtime_3_wh"]!=null){logcount++;}
            if(dn[d]["logtime_16"]!=null){logcount++;}
            if(dn[d]["logtime_12"]!=null){logcount++;}
            if(dn[d]["logtime_1_nx"]!=null){logcount++;}
            if(dn[d]["logtime_1_xj"]!=null){logcount++;}
            dn[d]["logcount"] = logcount;

            ds.push(dn[d]);
        }
    }
    return ds;
}

export { 
    GetStaffList, 
    SearchHito,
    GetSignPoint,
    SearchGroupLs
}