// axios
import Axios from "axios";

const url = "https://interface.cctrip.cc";
//const url = "http://192.168.120.2:8880";

const login = (u,p) => {
    return Axios.post(
        url+'/api/user/login', {
        "u": u,
        "p": p,
    });
};

const decode_token = (u,p,token) => {
    return Axios.post(
        url+'/api/ad/decode_token', {
        "u": u,
        "p": p,
        "token":token
    });
};

export { 
    login,
    decode_token,
};